import { FaTimes } from "react-icons/fa";
import "./JoinRoomModal.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Modal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal")) {
      onClose();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let roomCode = event.target["room-code"].value.trim();

    if (!roomCode || typeof roomCode !== "string") {
      return;
    }

    navigate(`/game/${roomCode.toUpperCase()}`);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const input = document.querySelector(".room-code-input");

    if (input) {
      input.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="modal"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClick={handleBackdropClick}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="modal-title">Join a Room</h2>
          <button
            className="icon-btn close-modal-btn"
            aria-label="Close modal"
            onClick={onClose}
          >
            <FaTimes />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <label htmlFor="room-code">Room Code</label>
            <input
              type="text"
              id="room-code"
              className="room-code-input"
              placeholder="Enter the room code"
            />
          </div>
          <div className="modal-footer">
            <button className="btn" type="submit">
              JOIN
            </button>
            <button
              className="btn btn--cancel"
              aria-label="Close modal"
              onClick={onClose}
            >
              CANCEL
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
