import axios from "axios";
import React, { useState } from "react";
import useStore from "../store";
import { useParams } from "react-router-dom";
import "./Settings.css";
import {
  FaCheck,
  FaClipboard,
  FaEdit,
  FaSignOutAlt,
  FaSync,
  FaTimes,
} from "react-icons/fa";
import { FaDiceFive } from "react-icons/fa6";
import withTracking from "../utils/withTrackingClick";

const Settings = () => {
  const userId = useStore((state) => state.userId);
  const gameCode = useParams().gameCode;
  const game = useStore((state) => state.game);
  const setShowSettings = useStore((state) => state.setShowSettings);
  const setPlayer = useStore((state) => state.setPlayer);

  const [linkCopied, setLinkCopied] = useState(false);

  const [configTeams, setConfigTeams] = useState(game.config.teams);
  const [configAssassinCount, setConfigAssassinCount] = useState(
    game.config.assassinCount
  );
  const [configLanguage, setConfigLanguage] = useState(game.config.language);
  const [configWordPack, setConfigWordPack] = useState(game.config.wordPack);

  const isHost = (userId) => {
    if (game) {
      return game.host === userId;
    }
  };

  const config = {
    teams: configTeams,
    assassinCount: Number(configAssassinCount),
    language: configLanguage,
    wordPack: configWordPack,
  };

  const handleInitGame = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/init`, {
        userId,
        gameCode,
        config,
      })
      .then((response) => {});
  };

  const handleLeaveGame = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/leaveTeams`, {
        userId,
        gameCode,
      })
      .then((response) => {
        setShowSettings(false);
      });
  };

  const handleChangeName = () => {
    const newName = prompt("Enter new name");

    if (!newName) return;
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/changeName`, {
        userId,
        newName,
        gameCode,
      })
      .then((response) => {
        const data = response.data;
        if (data.newName) {
          localStorage.setItem("Cryptik_playerName", data.newName);
          setPlayer(data.newName, localStorage.getItem("Cryptik_playerToken"));
        }

        setShowSettings(false);
      });
  };

  const handleReInitGame = ({ shuffleTeams } = { shuffleTeams: false }) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/reInit`, {
        userId,
        gameCode,
        config,
        shuffleTeams,
      })
      .then((response) => {
        setShowSettings(false);
      });
  };

  const handleCopyGameLink = () => {
    navigator.clipboard.writeText(`cryptik.me/game/${gameCode}`);

    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  return (
    <div className="settings">
      <div
        className="settings__overlay"
        onClick={() => setShowSettings(false)}
      ></div>
      <div
        className={`settings__container ${
          game.status !== "Created" ? "settings__container--bottom" : ""
        }`}
      >
        {/* <button
          onClick={() => {
            socket.disconnect();
          }}
        >
          Disconnect
        </button> */}

        {game.status === "Created" && isHost(userId) && (
          <>
            <p className="settings__share-link">
              <span>1. Click this link to copy then send to your friends</span>
              <br />
              <em>
                {linkCopied ? (
                  <FaCheck className="settings__link-copied-icon" />
                ) : (
                  <button
                    className="settings__copy-link-btn"
                    onClick={withTracking(
                      handleCopyGameLink,
                      "CopyLink--button"
                    )}
                  >
                    <FaClipboard />
                  </button>
                )}
                <span
                  className="setting__copy-link-text"
                  onClick={withTracking(handleCopyGameLink, "CopyLink--text")}
                >
                  cryptik.me/game/{gameCode}
                </span>
              </em>
            </p>

            <p className="text-center mb--10">
              Or share the code <strong>{gameCode}</strong> with your friends
            </p>

            <p className="text-center mb--4">2. Confirm game settings</p>
          </>
        )}

        {game.status !== "Created" && (
          <button
            className="icon-btn settings-close-btn"
            onClick={() => {
              withTracking(setShowSettings(false), "CloseSettings");
            }}
          >
            <FaTimes />
          </button>
        )}

        {!isHost(userId) && game.status === "Created" && (
          <p className="flex justify-center mt--2">
            WAITING FOR HOST TO START GAME...
          </p>
        )}

        {isHost(userId) && (
          <>
            <div
              className={`settings__config ${
                game.status === "Created" && "settings__config--only"
              }`}
            >
              <div className="settings__config--actions">
                {game.status !== "Created" && (
                  <button
                    className="settings__basic-btn"
                    onClick={() =>
                      withTracking(handleReInitGame(), "RestartGame")
                    }
                  >
                    <FaSync className="mr--4" />
                    Restart
                  </button>
                )}
                {game.status !== "Created" && (
                  <button
                    className="settings__basic-btn"
                    onClick={() =>
                      withTracking(
                        handleReInitGame({ shuffleTeams: true }),
                        "ShuffleTeams"
                      )
                    }
                  >
                    <FaDiceFive className="mr--2 mt--1" />
                    Restart and shuffle teams
                  </button>
                )}
              </div>

              <div className="settings__config--allocations">
                <div>
                  <p htmlFor="language">Language</p>

                  <input
                    type="radio"
                    id="EN"
                    name="language"
                    value="EN"
                    checked={configLanguage === "EN"}
                    onChange={() =>
                      withTracking(setConfigLanguage("EN"), "ChangeLanguage-EN")
                    }
                  />
                  <label htmlFor="EN">EN</label>

                  <input
                    type="radio"
                    id="RU"
                    name="language"
                    value="RU"
                    checked={configLanguage === "RU"}
                    onChange={() =>
                      withTracking(setConfigLanguage("RU"), "ChangeLanguage-RU")
                    }
                  />
                  <label htmlFor="RU">РУ</label>

                  <input
                    type="radio"
                    id="CN"
                    name="language"
                    value="CN"
                    checked={configLanguage === "CN"}
                    onChange={() =>
                      withTracking(setConfigLanguage("CN"), "ChangeLanguage-CN")
                    }
                  />
                  <label htmlFor="CN">中</label>
                </div>
                <div style={{ textAlign: "center" }}>
                  <p>Number of Teams</p>
                  <input
                    type="radio"
                    id="2teams"
                    name="teams"
                    value="2"
                    checked={configTeams === 2}
                    onChange={() =>
                      withTracking(setConfigTeams(2), "ChangeTeamsCount-2")
                    }
                  />

                  <label htmlFor="2teams">2</label>

                  <input
                    type="radio"
                    id="3teams"
                    name="teams"
                    value="3"
                    checked={configTeams === 3}
                    onChange={() =>
                      withTracking(setConfigTeams(3), "ChangeTeamsCount-3")
                    }
                  />

                  <label htmlFor="3teams">3</label>
                </div>

                <div style={{ textAlign: "center" }}>
                  <p htmlFor="assassinCount">Number of Black Words</p>
                  <em className="assassins--hint">
                    "A team that finds a black word loses immediately"
                  </em>

                  <input
                    type="radio"
                    id="1assassin"
                    name="assassinCount"
                    value="1"
                    checked={configAssassinCount === 1}
                    onChange={() =>
                      withTracking(
                        setConfigAssassinCount(1),
                        "ChangeAssassinCount-1"
                      )
                    }
                  />
                  <label htmlFor="1assassin">1</label>

                  <input
                    type="radio"
                    id="2assassins"
                    name="assassinCount"
                    value="2"
                    checked={configAssassinCount === 2}
                    onChange={() =>
                      withTracking(
                        setConfigAssassinCount(2),
                        "ChangeAssassinCount-2"
                      )
                    }
                  />
                  <label htmlFor="2assassins">2</label>

                  <input
                    type="radio"
                    id="3assassins"
                    name="assassinCount"
                    value="3"
                    checked={configAssassinCount === 3}
                    onChange={() =>
                      withTracking(
                        setConfigAssassinCount(3),
                        "ChangeAssassinCount-3"
                      )
                    }
                  />
                  <label htmlFor="3assassins">3</label>

                  {/* <input
                    type="number"
                    id="assassinCount"
                    name="assassinCount"
                    value={configAssassinCount}
                    onChange={(e) => {
                      setConfigAssassinCount(e.target.value);
                    }}
                  /> */}
                </div>

                {configLanguage === "EN" && (
                  <div>
                    <p>Custom Word Pack</p>
                    <select
                      name="wordPack"
                      id="wordPack"
                      value={configWordPack}
                      onChange={(e) => setConfigWordPack(e.target.value)}
                    >
                      <option value="DEFAULT">Default</option>
                      <option value="FANTASY-MYTHOLOGY">
                        Fantasy-Mythology (Free)
                      </option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {game.status === "Created" && isHost(userId) && (
          <div className="text-center mt--6">
            <button
              onClick={() => withTracking(handleInitGame(), "InitGame")}
              className="mb--2 settings__create-game-btn"
            >
              CONFIRM
            </button>
          </div>
        )}

        {game.status !== "Created" && (
          <div className="settings__general">
            <button
              className="settings__basic-btn"
              onClick={() => withTracking(handleChangeName(), "ChangeName")}
            >
              <FaEdit className="mr--2 mt--2" />
              Change Name
            </button>
            <button
              className="settings__basic-btn"
              onClick={() => withTracking(handleLeaveGame(), "LeaveTeams")}
            >
              <FaSignOutAlt className="mr--2 mt--2" />
              Leave Teams
            </button>
          </div>
        )}

        {!isHost(userId) && <hr />}

        {game.status !== "Created" ||
          (game.status === "Created" && !isHost(userId) && (
            <p className="settings__share-link">
              <span>Share the room link with your friends</span>
              <br />
              <em>
                {linkCopied ? (
                  <FaCheck className="settings__link-copied-icon" />
                ) : (
                  <button
                    className="settings__copy-link-btn"
                    onClick={withTracking(
                      handleCopyGameLink,
                      "CopyLink--button"
                    )}
                  >
                    <FaClipboard />
                  </button>
                )}
                <span
                  className="setting__copy-link-text"
                  onClick={withTracking(handleCopyGameLink, "CopyLink--text")}
                >
                  cryptik.me/game/{gameCode}
                </span>
              </em>
            </p>
          ))}
      </div>
    </div>
  );
};

export default Settings;
