import React from "react";
import useStore from "../store";
import { Navigate, useNavigate } from "react-router-dom";
import "./Landing.css";
import { Helmet } from "react-helmet";
import { FaInstagram } from "react-icons/fa";
import structuredDataVideoGame from "../constants/structuredDataVideoGame";

const Landing = () => {
  // const { playerName, accessCode } = useStore();
  const { playerName, userId } = useStore();
  const navigate = useNavigate();

  if (playerName && userId) {
    return <Navigate to="/play" />;
  }

  return (
    <>
      <Helmet>
        <title>Play Codenames Online - Cryptik | Free Word Strategy Game</title>
        <link
          rel="canonical"
          href={`https://www.cryptik.me${window.location.pathname}`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredDataVideoGame)}
        </script>
      </Helmet>
      <div>
        <img
          src="/cryptik-logo-transparent.png"
          alt="Logo for Cryptik, a game inspired by codenames"
          className="landing__logo"
          width={60}
          height={60}
        />
        <h1 className="landing__title">Play Codenames Online for Free – Try Cryptik!</h1>
        <h2 className="landing__subtitle">
         A Codenames-Inspired Game with Online Multiplayer
        </h2>
        <button className="play-now-btn" onClick={() => navigate("/play")}>
          PLAY NOW
        </button>

        <div>
          <div className="highlight-box">
            <h4>How to play</h4>

            <ol className="manual">
              <li>
                <strong>Spymaster:</strong> Lead your team to victory! Give
                clues to identify your team's words.
              </li>
              <li>
                <strong>Operative:</strong> Crack the clues! Pick words you
                think match your Spymaster's hints.
              </li>
              <li>
                <strong>A clue:</strong> One word and a number. Example: "Ocean
                3" hints at 3 'ocean'-related words on the board.
              </li>
              <li>
                <strong>Winning:</strong> Uncover all your team's words first to
                win.
              </li>
              <li>
                <strong>Beware the Assassin:</strong> Avoid the assassin (black)
                word, or your team loses instantly!
              </li>
              <li>
                <strong>Neutral Words: </strong>
                Guessing a neutral (gray) word ends your turn, so guess wisely.
              </li>
              <li>
                <strong>Cryptik</strong> is best enjoyed with <strong>voice chat</strong>. Use Discord or a similar platform to communicate with your team. 
              </li>
            </ol>

            <em>Good luck, and have fun!</em>
          </div>
        </div>

        <div className="mt--6 text-center mb--2">
          <p className="mb--4 font--light">For more updates</p>
          <a
            title="Follow Cryptik game on Instagram"
            href="https://instagram.com/playcryptik"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <FaInstagram size={25} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Landing;
