module.exports = {
  "@context": "http://schema.org",
  "@type": "Game",
  name: "Cryptik",
  alternateName: "Online Word Strategy Game",
  description:
    "Cryptik is an online word strategy game inspired by Codenames. Play for free with friends.",
  genre: ["Puzzle", "Strategy", "Word"],
  url: "https://cryptik.me",
  image: "https://cryptik.me/cryptik-logo.png",
  applicationCategory: "Game",
  operatingSystem: "Web",
  sameAs: [
    "https://instagram.com/playcryptik",
    "https://facebook.com/playcryptik",
  ],
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "4.5",
    ratingCount: "1234",
  },
  offers: {
    "@type": "Offer",
    price: "0.00",
    priceCurrency: "USD",
    availability: "http://schema.org/InStock",
  },
  publisher: "Sami",
  playMode: "Multiplayer",
  browserRequirements: "Requires JavaScript and HTML5",
  releaseDate: "2023-11-23",
  keywords:
    "code names, code, names, online, game, strategy game, puzzle, word game, free, f2p, multiplayer, web, browser, game",
};
